import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout/layout";

import PageContainer from "../../components/layout/page-container";
import PageSection from "../../components/layout/page-section";
import FilterBar from "../../components/browser/filter-bar";

class PerformancesByArtistPage extends React.Component {
  constructor({ data }) {
    super();

    this.data = data;
  }

  render() {
    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <PageSection background="dark-accent">
            <h1 className="page-title">Performances by Artist</h1>
          </PageSection>
          <PageSection topPadding="md">
            <FilterBar filterSet="performances" currentFilter="artist" />
            <ul className="listing-grid">
              {this.data.allArtistsJson.edges.map(({ node }) => (
                <li key={node.reference} className="listing-grid__cell">
                  <Link to={`/performances/artist/${node.reference}`}>{node.canonical_name}</Link>
                </li>))}
            </ul>
            <FilterBar filterSet="performances" currentFilter="artist" />
          </PageSection>
        </PageContainer>
      </Layout>
    );
  }
}

export default PerformancesByArtistPage;

export const query = graphql`
  query PerformancesByArtistQuery {
    allArtistsJson (sort: {fields: sort_name}) {
      edges {
        node {
          reference
          canonical_name
        }
      }
    }
  }
`